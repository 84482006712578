module.exports = {
    "metadata": {
        "name": ".NET Thailand",
        "short_name": ".NET Thailand",
        "description": ".NET Thailand community",
        "language": "en",
        "url": "http://localhost",
        "pathPrefix": "/",
        "uaTrackingId": "UA-204153201-1",
        "siteImage": "/assets/dotnetthailand-ogimage-dark.png",
        "favicon": "/assets/favicon.png",
        "themeColor": "#ff0000",
        "siteUrl": "https://www.dotnetthailand.com/"
    },
    "header": {
        "logo": "/assets/code.svg",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "Home",
                "link": "/",
                "external": false
            },
            {
                "text": ".NET Thailand group",
                "link": "https://www.facebook.com/groups/dotnetthailand",
                "external": true
            },
            {
                "text": "Credit to our contributors",
                "link": "/contributors",
                "external": false
            }
        ],
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": false,
        "groups": [
            {
                "order": 0,
                "path": "/faq",
                "title": ":question: .NET FAQ"
            },
            {
                "order": 1,
                "path": "/web-frameworks",
                "title": ":fire: Web Frameworks"
            },
            {
                "order": 2,
                "path": "/storage",
                "title": ":floppy_disk: storage"
            },
            {
                "order": 3,
                "path": "/testing",
                "title": ":heavy_check_mark: Testing"
            },
            {
                "order": 4,
                "path": "/frontend-web",
                "title": ":iphone: Front-end Web"
            },
            {
                "order": 5,
                "path": "/cloud-hosting",
                "title": ":cloud: Cloud & Hosting"
            },
            {
                "order": 6,
                "path": "/programming-cookbook",
                "title": ":blue_book: Programming cookbook"
            },
            {
                "order": 7,
                "path": "/to-other-languages",
                "title": ":bicyclist: To other languages"
            },
            {
                "order": 8,
                "path": "/awesome-dotnet-thailand-projects",
                "title": ":package: Awesome .NET Thailand Projects"
            },
            {
                "order": 9,
                "path": "/3rd-party-api",
                "title": ":briefcase: 3rd party API"
            }
        ],
        "links": [
            {
                "text": ".NET Thailand",
                "link": "https://www.facebook.com/groups/dotnetthailand"
            },
            {
                "text": "React",
                "link": "https://reactjs.org"
            }
        ],
        "poweredBy": {
            "trademark": "/assets/gatsby.png",
            "name": "GatsbyJS",
            "link": "https://www.gatsbyjs.org"
        }
    },
    "pwa": {
        "manifest": {
            "name": ".NET Thailand",
            "short_name": ".NET Thailand",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "static/assets/favicon.png",
            "description": ".NET Thailand community",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "en"
        },
        "enabled": true
    },
    "social": {
        "facebook": "https://www.facebook.com/groups/dotnetthailand",
        "github": "https://github.com/dotnetthailand/dotnetthailand.github.io",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "",
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": true,
            "location": "https://github.com/dotnetthailand/dotnetthailand.github.io",
            "type": "github",
            "repo": "dotnetthailand/dotnetthailand.github.io"
        },
        "search": {
            "enabled": true,
            "indexName": "docs",
            "algoliaAppId": "ZZXWSXQ8B7",
            "algoliaSearchKey": "47cb9b1c68ab2b0f43eadaca36fbabfe",
            "algoliaAdminKey": "fda4ba9807a1e6fd3fffac0799d59733",
            "excerptSize": 20000,
            "engine": "algolia",
            "placeholder": "Search",
            "startComponent": "icon",
            "debounceTime": 450,
            "snippetLength": 23,
            "hitsPerPage": 8,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 3,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 4
        },
        "previousNext": {
            "enabled": false,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "default",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": true,
            "showIcon": false,
            "title": "BooGi Docs",
            "copyright": "2020, Mateusz Filipowicz",
            "webMaster": "Mateusz Filipowicz",
            "managingEditor": "Mateusz Filipowicz",
            "categories": [
                "Docs as Code",
                "GatsbyJS"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "boogi"
        },
        "darkMode": {
            "enabled": true,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": true,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        },
        "content": {
            "rootPath": "content"
        },
        "facebookSDK": {
            "appId": "891898928268139"
        }
    }
};